import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class UsuarioLogic {
    static instance = null;
    static createInstance() {
        var object = new UsuarioLogic();
        return object;
    }

    static getInstance() {
        if (!UsuarioLogic.instance) {
            UsuarioLogic.instance = UsuarioLogic.createInstance();
        }
        return UsuarioLogic.instance;
    }

    constructor() {
    }

    async validar(objUsuario){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/validar";
            var encryptedEmail = Buffer.from(objUsuario.email).toString('base64');
            var encryptedClave = Buffer.from(objUsuario.clave).toString('base64');

            var objRequest = {};
            objRequest['email'] = encryptedEmail;
            objRequest['clave'] = encryptedClave;

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] validar()::ERROR >>> "+error);
        }
    }

    async listar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] listar()::ERROR >>> "+error);
        }
    }

    async crear(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var encryptedEmail = Buffer.from(objRequest.email).toString('base64');
            var encryptedClave = Buffer.from(objRequest.clave).toString('base64');

            var formData = new FormData();
            formData.append('idPerfil', objRequest.idPerfil);
            formData.append('nombre', objRequest.nombre);
            formData.append('primerApellido', objRequest.primerApellido);
            formData.append('segundoApellido', objRequest.segundoApellido);
            formData.append('email', encryptedEmail);
            formData.append('clave', encryptedClave);
            formData.append('fileImagen', objRequest.fileImagen);

            var urlRequest = ENV_APIPATH+"/usuario/crear";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] crear()::ERROR >>> "+error);
        }
    }

    async editar(objUsuario){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var encryptedEmail = Buffer.from(objUsuario.email).toString('base64');
            var encryptedClave = Buffer.from(objUsuario.clave).toString('base64');

            var formData = new FormData();
            formData.append('id', objUsuario.id);
            formData.append('idPerfil', objUsuario.idPerfil);
            formData.append('nombre', objUsuario.nombre);
            formData.append('primerApellido', objUsuario.primerApellido);
            formData.append('segundoApellido', objUsuario.segundoApellido);
            formData.append('email', encryptedEmail);
            formData.append('clave', encryptedClave);
            formData.append('fileImagen', objUsuario.fileImagen);

            var urlRequest = ENV_APIPATH+"/usuario/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] editar()::ERROR >>> "+error);
        }
    }

    async detalle(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/detalle";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] detalle()::ERROR >>> "+error);
        }
    }

    async invitar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/invitar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] verificar()::ERROR >>> "+error);
        }
    }

    async verificar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/verificar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] verificar()::ERROR >>> "+error);
        }
    }

    async borrar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] borrar()::ERROR >>> "+error);
        }
    }

    async olvideClaveEnviar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/olvideclave/enviar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] olvideClave()::ERROR >>> "+error);
        }
    }

    async olvideClaveVerificar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/olvideclave/verificar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] verificarOlvideClave()::ERROR >>> "+error);
        }
    }

    async olvideClaveActualizar(objUsuario){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/usuario/olvideclave/actualizar";
            var encryptedClave = Buffer.from(objUsuario.clave).toString('base64');

            var objRequest = {};
            objRequest['hash'] = objUsuario.hash;
            objRequest['clave'] = encryptedClave;

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] olvideClaveActualizar()::ERROR >>> "+error);
        }
    }
}

const instance = UsuarioLogic.getInstance();
export default instance;